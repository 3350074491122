<template>
    <v-dialog
        v-if="isVisible"
        v-model="isVisible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="isPending" >

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>                        
                        <v-form ref="form" lazy-validation>
                            <v-field-edit-date 
                                :label="label" 
                                v-model="contractDate"    
                            />
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isValid"
                >
                    {{ $t("Отправить") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "CSCFormAnAnswer060X0001Dlg",
   
    data () {
        return {
            title: "Сформировать_ответ",            
        }
    },
    computed: {
        ...mapGetters('dialogs/cscFormAnAnswer060X0001', ['isVisible', 'isValid', 'isPending']),
        ...mapGetters({ requiredRule: 'getRequiredRule' }),

        contractDate: {
            get: function() {
                return this.$store.getters['dialogs/cscFormAnAnswer060X0001/contractDate'];
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer060X0001/SET_CONTRACT_DATE', v); 
                this.validateFormMethod();
            }
        },
        label() {
            return this.$t('Дата_заключения_договора');
        }
    },
    methods: {
        ...mapActions('dialogs/cscFormAnAnswer060X0001', ['ok', 'cancel']),
        
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    }
}
</script>